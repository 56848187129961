<template>
  <router-link :to="props.to" class="sidebar-item block py-12 font-light text-neutral-600" exact>
    {{ props.label }}
  </router-link>
</template>

<script setup lang="ts">
  interface Props {
    label: string;
    to: string;
  }
  const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
  .sidebar-item.router-link-active {
    @apply font-bold text-primary;
  }
</style>
