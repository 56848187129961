import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { Filter } from '@/util/Filter';
import type { Page } from '@/util/Paging';
import type { Sorting } from '@/util/Sorting';

import { useApi } from '@/composables/api/useApi';
import { PageImpl, PageRequest } from '@/util/Paging';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOpenItemStore = defineStore('open-item-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const openItems = ref<Page<OutstandingDebtDto>>(new PageImpl([], new PageRequest(10, 1), 0));

  // outstandingdebts?app_version=2023.1.4512813617&pagination.page=0&pagination.records=20&sorting.col=invoiceDate&filters.search=&filters.due=0
  async function loadData(pageRequest: PageRequest, filter: Filter, sorting: Sorting) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.search': filter.search,
        'filters.due': filter.due ? `${filter.due}` : '0',
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/outstandingdebts?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.outstandingDebts) {
        const outstandingDebts = container.data.outstandingDebts as Array<OutstandingDebtDto>;
        openItems.value = api.getResultPage(container, outstandingDebts);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    openItems,
    loadData,
  };
});
