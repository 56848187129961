<template>
  <div>
    <div class="mb-8">
      <router-link to="/statistics" class="router-link" :title="t('statistics.revenue')">
        {{ t('statisticsDashboard') }}
      </router-link>
    </div>
    <DashboardWidgetStatisticsTable :infoStatistics="props.infoStatistics" />
  </div>
</template>

<script setup lang="ts">
  import type { StatisticsInfoDto } from '@/domain/StatisticsInfoDto';

  import DashboardWidgetStatisticsTable from '@/modules/statistics/components/dashboard-widget/table/DashboardWidgetStatisticsTable.vue';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    infoStatistics: StatisticsInfoDto;
  }
  const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
  .router-link {
    @apply text-xl font-bold text-white;
  }
</style>
