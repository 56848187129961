<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold">{{ t('customers.addNote') }}</div>
    </template>
    <template #content>
      <div class="flex flex-col gap-16 md:min-w-[333px]">
        <AgFormGroup :label="t('customers.subject')">
          <AgFormInput
            v-model="subject"
            :validation-state="subjectValidationState"
            :error-message="t('customers.subjectRequired')"
            :max-lenght="50"
          />
        </AgFormGroup>
        <AgFormGroup :label="t('customers.note')">
          <AgFormTextarea
            v-model="note"
            :rows="10"
            :validation-state="noteValidationState"
            :error-message="t('customers.noteRequired')"
            :max-lenght="300"
            :resize="false"
          />
        </AgFormGroup>
      </div>
    </template>
    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" @click="closeModal"> {{ t('customers.cancel') }}</AgButton>
      <AgButton variant="primary" @click="addCustomerNote">{{ t('customers.apply') }}</AgButton>
    </template>
  </AgModal>
</template>
<script setup lang="ts">
  import type { CustomerNotesRequest } from '@/domain/internal/CustomerNotesRequest';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useCustomerNotesStore } from '@/modules/customers/composables/useCustomerNotesStore';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useCustomerNotesStore();
  const { newCustomerNote } = store;

  interface Props {
    modelValue?: boolean;
    customerId: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const subject = ref<string>('');
  const note = ref<string>('');
  const subjectValidationState = ref<boolean>(true);
  const noteValidationState = ref<boolean>(true);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const addCustomerNote = async function () {
    if (!subject.value || !note.value) {
      return;
    }
    if (subject.value.length < 3) subjectValidationState.value = false;
    else subjectValidationState.value = true;

    if (note.value.length < 3) noteValidationState.value = false;
    else noteValidationState.value = true;
    if (noteValidationState.value === false || subjectValidationState.value === false) return;
    const request: CustomerNotesRequest = {
      customerId: props.customerId,
      subject: subject.value,
      note: note.value,
    };
    await newCustomerNote(request);
    subject.value = '';
    note.value = '';
    closeModal();
  };

  watch(
    [internalValue],
    () => {
      subjectValidationState.value = true;
      noteValidationState.value = true;
    },
    { immediate: true }
  );
</script>
