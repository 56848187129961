<template>
  <div class="m-24 border border-neutral-550">
    <div class="p-12">
      <div v-if="items.length === 0">
        <div class="font-bold">{{ t('customers.noArticleFound') }}</div>
      </div>
      <div v-else>
        <div class="max-h-512 overflow-y-auto py-12">
          <div class="tableHeader">
            <div class="headerColumn">{{ t('customers.articleCode') }}</div>
            <div class="headerColumn">{{ t('customers.article') }}</div>
            <div class="headerColumn">{{ t('customers.quantity') }}</div>
            <div class="headerColumn">{{ t('customers.discount') }}</div>
            <div class="headerColumn">{{ t('customers.amount') }}</div>
          </div>
          <div v-for="item in props.items" :key="item.article.id">
            <div class="table">
              <div class="tableColumn">{{ item.article.code }}</div>
              <div class="tableColumn">
                {{ getTranslatedText(item.article.title) }}
              </div>
              <div class="tableColumn">
                {{ item.quantity }}
                {{ getTranslatedText(item.packaging.unit.title) }}
              </div>
              <div class="tableColumn">{{ item.discount1 }} %</div>
              <div class="tableColumn">
                {{ formatNumberToEuro(item.unitPrice) }}
                ({{ formatNumberToEuro(item.tradeAgreement.price) }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    items: Array<OrderItemDto>;
  }
  const props = defineProps<Props>();
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-auto grid-flow-col-dense gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid auto-cols-auto grid-flow-col-dense gap-12 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .center {
    @apply justify-center;
  }
</style>
