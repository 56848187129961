<template>
  <div>
    <div v-if="props.sentOffers.length === 0">
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.offersSent') }}</span>
      </div>
      <div class="pt-12">{{ t('customers.noDataFound') }}!</div>
    </div>
    <div v-else>
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.offersSent') }}</span>
      </div>
      <div class="max-h-512 py-12">
        <div class="tableHeader">
          <div class="headerColumn">{{ t('customers.offerNumber') }}</div>
          <div class="headerColumn">{{ t('customers.offerNumber') }}</div>
          <div class="headerColumn">{{ t('customers.customerNumber') }}</div>
          <div class="headerColumn">{{ t('customers.deliveryMethod') }}</div>
          <div class="headerColumn">{{ t('customers.deliveryTerm') }}</div>
          <div class="headerColumn right">&nbsp;</div>
        </div>
        <div v-for="offer in props.sentOffers" :key="offer.gid">
          <AgCollapsible show-icon class="border-t border-neutral-500">
            <template #header>
              <div class="table">
                <div class="tableColumn">{{ offer.offerNumber }}</div>
                <div class="tableColumn">
                  {{ formatDate(offer.offerDate) }}
                </div>
                <div class="tableColumn">
                  {{ offer.customer.code }}
                </div>
                <div class="tableColumn">
                  {{ getTranslatedText(offer.deliveryMethod?.title) }}
                </div>
                <div class="tableColumn">
                  {{ getTranslatedText(offer.deliveryTerm?.title) }}
                </div>
                <div class="tableColumn right space-x-8">
                  <AgButton variant="ghost" @click.stop="onCopy(offer)" :disabled="isCustomerBlocked">
                    <template #icon><IcCopy /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onReview(offer)">
                    <template #icon><IcShow /></template>
                  </AgButton>
                </div>
              </div>
            </template>
            <template #default>
              <ArticlesTable :items="offer.items" />
            </template>
          </AgCollapsible>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OfferDto } from '@/domain/offerData/OfferDto';

  import IcCopy from '@/components/icons/IcCopy.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ArticlesTable from '@/modules/customers/components/ArticlesTable.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOffers } from '@/modules/offers/composables/useOffers';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { cloneDeep } from 'lodash';

  const { t } = i18n.global;

  const { copyOffer } = useOfferFactory();
  const { saveOffer } = useOffers();
  const { formatDate } = useDateFormatting();
  const { getTranslatedText } = useTranslatedText();

  interface Props {
    sentOffers: Array<OfferDto>;
    isCustomerBlocked: boolean;
  }
  const props = defineProps<Props>();

  const onReview = async function (offer: OfferDto) {
    await router.push({
      name: 'offer-wizard',
      params: { gid: offer.gid, step: 'review' },
    });
  };

  const onCopy = async function (offer: OfferDto) {
    const newOffer = await copyOffer(cloneDeep(offer));

    await saveOffer(newOffer).then(
      async () =>
        await router.push({
          name: 'offer-wizard',
          params: { gid: newOffer.gid, step: 'review' },
        })
    );
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid auto-cols-fr grid-flow-col gap-12 py-8;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }

  .tooltip {
    @apply invisible absolute -mt-32 rounded bg-neutral-500 p-4;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-20;
  }

  .signal {
    padding: 0.2em;
    margin: 0 0.7em 0 0;
    background-color: #28a745;
  }
</style>
