<template>
  <AgModal v-if="internalValue" fit-height fit-width>
    <template #header>
      <div class="text-xl font-bold pb-16">{{ t('orders.sendOrderByEmail') }}</div>
      <div class="text-s-13 font-light text-neutral-550 italic max-w-[400px] break-all">
        {{ t('orders.sendOrderByEmailReminder') }}
      </div>
    </template>
    <template #content>
      <!-- MAIL DATA -->
      <div class="space-y-16 py-16 md:min-w-[333px]">
        <div>
          <AgFormGroup :label="t('openItems.mail')" required>
            <AgFormSelect v-model="whichEmail" :options="mailToOptions" :disabled="isEmailChangingDisabled" />
          </AgFormGroup>
        </div>
        <div v-if="whichEmail !== WhichEmail.COMPANY_ORDER">
          <AgFormGroup v-if="!isEmailChangingDisabled" :label="t('orders.mail')" required>
            <AgFormInput v-model="email" type="email" :validation-state="errorMessage.length === 0" />
          </AgFormGroup>
          <div v-if="errorMessage.length > 0" class="text-danger p-4">
            {{ errorMessage }}
          </div>
        </div>
        <div>
          <AgFormGroup :label="t('orders.selectLanguage')" required>
            <AgFormSelect v-model="language" :options="langOptions" />
          </AgFormGroup>
        </div>
        <div>
          <AgFormGroup :label="t('orders.mailContent')">
            <AgFormTextarea v-model="emailContent" />
          </AgFormGroup>
        </div>
      </div>

      <div class="mx-auto py-24">
        <div v-if="isLoading">
          <IcLoading />
        </div>
        <div v-if="isError" class="text-danger">
          {{ t('orders.emailRequestError') }}
        </div>
      </div>
    </template>

    <template #footer>
      <AgButton variant="secondary" @click.stop="closeModal" :disabled="isLoading">
        {{ t('orders.cancel') }}
      </AgButton>
      <AgButton @click.stop="sendEmail" :disabled="isSaveButtonDisabled">
        {{ t('orders.sendMail') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { OrderEmailRequest } from '@/domain/email/OrderEmailRequest';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import { useEmail } from '@/composables/useEmail';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { config } from '@/config/config';
  import { WhichEmail } from '@/domain/enumeration/WhichEmail';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t, locale } = i18n.global;

  const { resolveHierarchyUsers } = useHierarchy();

  const orderStore = useOrderWizardStore();
  const { order } = storeToRefs(orderStore);

  const { sendOrderEmail } = useEmail();

  interface Props {
    modelValue?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const errorMessage = ref<string>('');
  const language = ref<string>(locale.value);
  const email = ref<string>('');
  const whichEmail = ref<WhichEmail>(WhichEmail.CUSTOMER_ORDER);
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const usersEmail = ref<Map<number, string>>(new Map());
  const isEmailChangingDisabled = config.company.enableOrderEmailRecipientChange === false;

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const isSaveButtonDisabled = computed(() => {
    if (!order.value) {
      return true;
    }
    if (language.value === '') {
      return true;
    }
    if (whichEmail.value === WhichEmail.COMPANY_ORDER) {
      return false;
    }
    return isLoading.value || errorMessage.value.length > 0;
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const mailToOptions = computed(() => {
    const options: Array<AgFormSelectOption> = [
      {
        key: WhichEmail.AGENT_ORDER,
        label: t('orders.agent'),
      },
      {
        key: WhichEmail.CUSTOMER_ORDER,
        label: t('orders.customer'),
      },
      {
        key: WhichEmail.COMPANY_ORDER,
        label: t('orders.company'),
      },
    ];
    return options;
  });

  const emailContent = computed({
    get: () => t('orders.mailDefaultText', 1, { locale: language.value }),
    set: (newValue) => {
      emit('update:modelValue', newValue);
    },
  });

  const langOptions = computed(() => {
    const languages: Array<AgFormSelectOption> = Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: t(`orders.language.${lang}`),
    }));
    return languages;
  });

  const sendEmail = function () {
    if (!order.value || !order.value.gid) {
      return;
    }

    isLoading.value = true;
    isError.value = false;

    const request: OrderEmailRequest = {
      emailTo: email.value,
      language: language.value,
      text: emailContent.value,
      whichEmail: whichEmail.value,
    };
    isLoading.value = true;
    sendOrderEmail(order.value.gid, request)
      .then(() => {
        isLoading.value = false;
        closeModal();
      })
      .catch(() => {
        isLoading.value = false;
        isError.value = true;
        closeModal();
      });
  };

  const validateEmail = function (email: string) {
    if (whichEmail.value === WhichEmail.COMPANY_ORDER) {
      errorMessage.value = '';
      return;
    }
    const regexp = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const isValidEmail = regexp.test(email);
    if (isValidEmail) {
      errorMessage.value = '';
    } else {
      if (!email || email.trim().length === 0) {
        errorMessage.value = t('orders.emailRequired');
      } else {
        errorMessage.value = t('orders.emailNotValid');
      }
    }
  };

  watch(email, (value) => validateEmail(value), { immediate: true });

  watch(
    whichEmail,
    (value) => {
      if (value === WhichEmail.CUSTOMER_ORDER) {
        email.value = order.value?.invoiceAddress.email ?? '';
      } else if (value === WhichEmail.AGENT_ORDER) {
        const agentId = order.value?.agentId ?? undefined;
        if (agentId) {
          email.value = usersEmail.value.get(agentId) ?? '';
        } else {
          email.value = '';
        }
      } else {
        email.value = '';
      }
      validateEmail(email.value);
    },
    { immediate: true }
  );

  watch(
    () => props.modelValue,
    async (value) => {
      if (value) {
        const usersOfHierarchy = await resolveHierarchyUsers();
        usersOfHierarchy.forEach((user) => {
          usersEmail.value.set(user.id, user.email);
        });
        isLoading.value = false;
        isError.value = false;
        if (isEmailChangingDisabled) {
          whichEmail.value = WhichEmail.COMPANY_ORDER;
          email.value = '';
        } else {
          whichEmail.value = WhichEmail.CUSTOMER_ORDER;
          email.value = order.value?.invoiceAddress.email ?? '';
        }
        validateEmail(email.value);
      }
    },
    { immediate: true }
  );
</script>
