<template>
  <div>
    <div class="my-16 flex items-center justify-between">
      <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="props.offers.length" />
      <AgButton class="sm:min-w-[359px]" @click="onNew">{{ t('offers.newOffer') }}</AgButton>
    </div>

    <div>
      <AgPaginator v-model="currentPage" :total-pages="totalPages" />
      <div class="tableHeader grid-cols-[minmax(150px,_1fr)_130px_130px_200px_200px]">
        <span>{{ t('offers.customerName') }}</span>
        <div class="flex flex-col">
          <span>{{ t('offers.agent') }}</span>
          <span>{{ t('offers.user') }}</span>
        </div>
        <span>{{ t('offers.customerStatus') }}</span>
        <span>{{ t('offers.date') }}</span>
        <span class="flex justify-end">{{ t('offers.offerNumber') }}</span>
      </div>
      <div v-for="offer in filteredOffers" :key="offer.gid">
        <OfferTableRowCompleted
          :customer-status="isCustomerBlocked(offer.customer.id)"
          :offer="offer"
          class="cursor-pointer"
          @click="openOffer(offer)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { OfferDto } from '@/domain/offerData/OfferDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import OfferTableRowCompleted from '@/modules/offers/components/offer-table/offer-table-row/OfferTableRowCompleted.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { tables, isFullReloading, isLoading } = useMasterData();

  interface Props {
    offers: Array<OfferDto>;
  }
  const props = defineProps<Props>();

  const customers = ref<Array<CustomerDto>>([]);
  const currentPage = ref(1);
  const pageSize = ref(10);

  const emit = defineEmits(['new-entry', 'open-offer']);

  const filteredOffers = computed(() => {
    const startIndex = (currentPage.value - 1) * pageSize.value;
    const endIndex = Math.min(startIndex + pageSize.value, props.offers.length);

    return props.offers.slice(startIndex, endIndex);
  });

  const totalPages = computed(() => Math.ceil(props.offers.length / pageSize.value));

  const onNew = function () {
    emit('new-entry');
  };

  const openOffer = function (offer: OfferDto) {
    emit('open-offer', offer);
  };

  const isCustomerBlocked = function (customerId?: number | null) {
    if (!customerId) {
      return false;
    }
    const customer = customers.value.find((entry) => {
      return entry.id === customerId;
    });
    if (!customer) {
      return true;
    }
    return customer.blocked;
  };

  const loadCustomers = async function () {
    customers.value = await tables.customers.toArray();
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadCustomers();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid min-w-fit items-center py-8 font-light text-neutral-550;
  }
</style>
