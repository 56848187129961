<template>
  <div class="relative">
    <input
      aria-label="AgFormInput"
      class="h-[40px] w-full rounded border-[1px] p-8 text-s-20 focus:outline-0 focus:border-black"
      v-model="internalValue"
      :id="id"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="inputTextClasses"
      :min="min"
      :max="max"
      :maxlength="maxLenght"
      @change="formatNumber(2)"
      @focusout="formatNumber(2)"
    />
    <div
      class="absolute inset-y-12 right-8 text-danger"
      v-if="validationState === false && type !== 'date' && type !== 'number'"
    >
      <IcAlert />
    </div>
    <div><slot name="icon"></slot></div>
    <div class="text-s-13 pt-4 italic text-danger" v-if="validationState === false">{{ props.errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
  import IcAlert from '@/components/icons/IcAlert.vue';

  import { computed, getCurrentInstance } from 'vue';

  const id = `form-input-${getCurrentInstance()?.uid}`;

  interface Props {
    placeholder?: string;
    modelValue?: unknown;
    type?: string;
    variant?: string;
    disabled?: boolean;
    right?: boolean;
    center?: boolean;
    validationState?: undefined | null | boolean;
    errorMessage?: undefined | null | string;
    min?: string | number;
    max?: string | number;
    maxLenght?: number | undefined;
    formatToDecimal?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    right: false,
    center: false,
    disabled: false,
    validationState: undefined,
    errorMessage: undefined,
    type: 'text',
    variant: undefined,
    maxLenght: undefined,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => {
      if (props.type === 'number' && newValue === '') {
        emit('update:modelValue', null);
      } else {
        emit('update:modelValue', newValue);
      }
    },
  });

  const inputTextClasses = computed(() => ({
    'border-danger': props.validationState === false,
    'border-neutral-550': props.validationState !== false && props.variant !== 'shoppingCart',
    'text-neutral-600 border-neutral-100 bg-neutral-100': props.disabled,
    'text-right': props.right,
    'text-center': props.center,
    'border-neutral-300 border-[2px] focus:border-link':
      props.validationState !== false && props.variant === 'shoppingCart',
  }));

  const formatNumber = function (decimals: number) {
    if (props.type === 'number' && typeof internalValue.value === 'number' && props.formatToDecimal) {
      internalValue.value = (internalValue.value as number).toFixed(decimals);
    }
  };
</script>

<style scoped lang="scss">
  .tooltip {
    @apply absolute bottom-[130%] left-1/2 z-50 flex -translate-x-1/2 text-s;
    @apply whitespace-nowrap rounded-md bg-black p-8 text-white opacity-0;
    @apply after:absolute after:left-1/2 after:top-full after:-translate-x-1/2;
    @apply after:border-4 after:border-t-black;
  }
</style>
