<template>
  <div class="menu px-32 py-12">
    <SidebarMenuItem label="Dashboard" to="/dashboard">
      <template #icon><IcDashboard /></template>
    </SidebarMenuItem>

    <FeatureFlag :feature-id="EModules.CUSTOMER_OVERVIEW">
      <SidebarMenuItem :label="t('customerOverviewSidebar')" to="/customers">
        <template #icon><IcClients /></template>
      </SidebarMenuItem>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.ARTICLES_OVERVIEW">
      <SidebarMenuItem :label="t('articlesOverviewSidebar')" to="/articles">
        <template #icon><IcCatalog /></template>
      </SidebarMenuItem>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.OFFER">
      <SidebarMenuItem :label="t('offerSidebar')" to="/offers">
        <template #icon><IcOffer /></template>
      </SidebarMenuItem>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.ORDER">
      <SidebarMenuItem :label="t('orderSidebar')" to="/orders">
        <template #icon><IcOrder /></template>
      </SidebarMenuItem>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.PROCESSED_DATA">
      <SidebarMenuItem :label="t('processedDataSidebar')" to="/processed-data">
        <template #icon><IcData /></template>
      </SidebarMenuItem>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.MESSAGES">
      <SidebarMenuItem :label="t('messageSidebar')" to="/messages">
        <template #icon><IcChat /></template>
      </SidebarMenuItem>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.OPEN_ITEMS">
      <SidebarMenuItemCollapsible :label="t('openPostSidebar')" to="/open-items">
        <template #icon><IcOutstanding /></template>

        <SidebarSubMenuItem :label="t('allOpenPostSidebar')" to="/open-items" />
        <SidebarSubMenuItem :label="t('byCustomerOpenPostSidebar')" to="/open-items/by-customer" />
        <SidebarSubMenuItem :label="t('byAgentOpenPostSidebar')" to="/open-items/by-agent" />
      </SidebarMenuItemCollapsible>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.STATISTICS">
      <SidebarMenuItemCollapsible :label="t('statisticSidebar')" to="/statistics">
        <template #icon><IcStatistics /></template>

        <SidebarSubMenuItem :label="t('allStatisticSidebar')" to="/statistics" />
        <SidebarSubMenuItem :label="t('byMonthStatisticSidebar')" to="/statistics/by-month" />
        <SidebarSubMenuItem :label="t('byArticleStatisticSidebar')" to="/statistics/by-article" />
        <SidebarSubMenuItem :label="t('byCustomerSidebar')" to="/statistics/by-customer" />
        <SidebarSubMenuItem :label="t('ownApplicationSidebar')" to="/statistics/own-application" />
      </SidebarMenuItemCollapsible>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.CONTINGENTS">
      <SidebarMenuItemCollapsible :label="t('contingentSidebar')" to="/contingents">
        <template #icon><IcContingents /></template>

        <SidebarSubMenuItem :label="t('byContGrArticleContingentSidebar')" to="/contingents" />
        <SidebarSubMenuItem :label="t('byCustomerContingentSidebar')" to="/contingents/by-customer" />
      </SidebarMenuItemCollapsible>
    </FeatureFlag>

    <FeatureFlag :feature-id="EModules.DOWNLOADS">
      <SidebarMenuItem :label="t('downloadSidebar')" to="/downloads">
        <template #icon><IcDownload /></template>
      </SidebarMenuItem>
    </FeatureFlag>
  </div>
</template>

<script setup lang="ts">
  import FeatureFlag from '@/components/framework/FeatureFlag.vue';
  import SidebarMenuItemCollapsible from '@/components/framework/the-sidebar/menu-collapsible/SidebarMenuItemCollapsible.vue';
  import SidebarMenuItem from '@/components/framework/the-sidebar/menu-item/SidebarMenuItem.vue';
  import SidebarSubMenuItem from '@/components/framework/the-sidebar/menu-subitem/SidebarSubMenuItem.vue';
  import IcCatalog from '@/components/icons/IcCatalog.vue';
  import IcChat from '@/components/icons/IcChat.vue';
  import IcClients from '@/components/icons/IcClients.vue';
  import IcContingents from '@/components/icons/IcContingents.vue';
  import IcDashboard from '@/components/icons/IcDashboard.vue';
  import IcData from '@/components/icons/IcData.vue';
  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcOffer from '@/components/icons/IcOffer.vue';
  import IcOrder from '@/components/icons/IcOrder.vue';
  import IcOutstanding from '@/components/icons/IcOutstanding.vue';
  import IcStatistics from '@/components/icons/IcStatistics.vue';

  import { EModules } from '@/config/EModules';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;
</script>

<style lang="scss" scoped>
  .menu > div {
    @apply border-t border-neutral-200;

    &:last-child {
      @apply border-b;
    }
  }
  .menu > a {
    @apply border-t border-neutral-200;

    &:last-child {
      @apply border-b;
    }
  }
</style>
