import type { RouteRecordRaw } from 'vue-router';

import authRequired from '@/middleware/authRequired';
import ByAgentIndexPage from '@/modules/open-items/pages/ByAgentIndexPage.vue';
import ByCustomerIndexPage from '@/modules/open-items/pages/ByCustomerIndexPage.vue';
import OpenItemsIndexPage from '@/modules/open-items/pages/OpenItemsIndexPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: OpenItemsIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/by-customer',
    component: ByCustomerIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/by-agent',
    component: ByAgentIndexPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];
