import { config } from '@/config/config';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { DiscountType } from '@/domain/enumeration/DiscountType';
import { SegmentType } from '@/domain/enumeration/SegmentType';
import type { DiscountDto } from '@/domain/masterData/DiscountDto';
import type { TradeAgreementDto } from '@/domain/masterData/TradeAgreementDto';
import { useMasterData } from './data/useMasterData';

export function useNordwal() {
  const masterData = useMasterData();

  const getPositionDiscounts = async function (
    customerType: CustomerType,
    articleId: number,
    priceGroupIds: Array<number>,
    customerId: number | null,
    priceListId: number,
    fromQt?: number
  ) {
    if (customerId) {
      const discounts = masterData.tables.discounts
        .where('[type+customerType+customerId+articleId]')
        .equals([DiscountType.POSITION, customerType, customerId, articleId]);

      if (fromQt !== undefined) {
        discounts.and((discount) => discount.fromQuantity <= fromQt);
      }
      const ds = await discounts.toArray();
      if (ds.length > 0) {
        return ds;
      }
      if (priceGroupIds && priceGroupIds.length > 0) {
        for (const pgId of priceGroupIds) {
          if (customerId) {
            const discounts = masterData.tables.discounts
              .where('[type+customerType+customerId+priceGroupId]')
              .equals([DiscountType.POSITION, customerType, customerId, pgId]);

            if (fromQt !== undefined) {
              discounts.and((discount) => discount.fromQuantity <= fromQt);
            }
            const foundDiscount = await discounts.toArray();
            if (foundDiscount.length > 0) {
              ds.push(...foundDiscount);
            }
          }
        }
        if (ds.length > 0) {
          return ds;
        }
      }
    }
    const priceListArticleDiscounts = masterData.tables.discounts
      .where('[type+customerType+priceListId+articleId]')
      .equals([DiscountType.POSITION, customerType, priceListId, articleId]);

    if (fromQt !== undefined) {
      priceListArticleDiscounts.and((discount) => discount.fromQuantity <= fromQt);
    }
    const ds = await priceListArticleDiscounts.toArray();
    if (ds.length > 0) {
      return ds;
    }
    if (priceGroupIds && priceGroupIds.length > 0) {
      for (const pgId of priceGroupIds) {
        const discounts = masterData.tables.discounts
          .where('[type+customerType+priceListId+priceGroupId]')
          .equals([DiscountType.POSITION, customerType, priceListId, pgId]);

        if (fromQt !== undefined) {
          discounts.and((discount) => discount.fromQuantity <= fromQt);
        }
        const foundDiscount = await discounts.toArray();
        if (foundDiscount.length > 0) {
          ds.push(...foundDiscount);
        }
      }
      if (ds.length > 0) {
        return ds;
      }
    }
    return [];
  };

  const getBaseTradeAgreements = async function (articleId: number, segment: string | null) {
    const tradeAgreements = await masterData.tables.tradeAgreements
      .where('[customerType+articleId]')
      .equals([CustomerType.ALL, articleId])
      .toArray();

    if (tradeAgreements.length > 0) {
      const tas = tradeAgreements.filter((ta) => filterBySegment(ta, segment));
      if (tas.length > 0) {
        return tas[0];
      }
    }
    return null;
  };

  const filterBySegment = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
      return true;
    }
    if (segment && segment === SegmentType.INDUSTRIAL) {
      return ta.industrialPrice > 0;
    }
    return ta.salesPrice > 0;
  };

  const getFinalDiscounts = function (ta: TradeAgreementDto, ds: DiscountDto[], segment: string | null) {
    const finalDiscounts: DiscountDto[] = [];
    if (ds.length > 1) {
      const dsPromotion = ds.filter((d) => d.customerType === CustomerType.PROMOTION);
      const dsCustomerArticle = ds.filter((d) => d.customerType === CustomerType.CUSTOMER_ARTICLE);
      const dsCustomerPriceGroup = ds.filter((d) => d.customerType === CustomerType.CUSTOMER_PRICEGROUP);
      const dsPriceListArticle = ds.filter((d) => d.customerType === CustomerType.PRICELIST_ARTICLE);
      const dsPriceListPriceGroup = ds.filter((d) => d.customerType === CustomerType.PRICELIST_PRICEGROUP);
      const dsAll = ds.filter((d) => d.customerType === CustomerType.ALL);

      const discounts: DiscountDto[] = [];
      if (dsPromotion.length > 0) {
        discounts.push(...dsPromotion);
      } else if (dsCustomerArticle.length > 0) {
        discounts.push(...dsCustomerArticle);
      } else if (dsCustomerPriceGroup.length > 0) {
        discounts.push(...dsCustomerPriceGroup);
      } else if (dsPriceListArticle.length > 0) {
        discounts.push(...dsPriceListArticle);
      } else if (dsPriceListPriceGroup.length > 0) {
        discounts.push(...dsPriceListPriceGroup);
      } else if (dsAll.length > 0) {
        discounts.push(...dsAll);
      }
      const groupByQuantity = discounts.reduce((group: { [key: number]: DiscountDto[] }, ds: DiscountDto) => {
        const fromQuantity = ds.fromQuantity;

        group[fromQuantity] = group[fromQuantity] ?? [];
        group[fromQuantity].push(ds);

        return group;
      }, {});
      if (Object.keys(groupByQuantity).length > 0) {
        Object.values(groupByQuantity).forEach((qtds) => {
          if (qtds.length > 1) {
            const discount = getMaxDiscount(ta, qtds, segment);
            finalDiscounts.push(discount);
          } else if (qtds.length === 1) {
            finalDiscounts.push(...qtds);
          }
        });
      } else {
        return discounts;
      }
    } else {
      return ds;
    }
    return finalDiscounts;
  };

  const getFinalDiscount = function (ta: TradeAgreementDto, ds: DiscountDto[], segment: string | null, qt: number) {
    let finalDiscount: DiscountDto | undefined = undefined;
    if (ds.length === 1) {
      finalDiscount = ds[0];
    } else if (ds.length > 1) {
      const dsPromotion = ds.filter((d) => d.customerType === CustomerType.PROMOTION);
      const dsCustomerArticle = ds.filter((d) => d.customerType === CustomerType.CUSTOMER_ARTICLE);
      const dsCustomerPriceGroup = ds.filter((d) => d.customerType === CustomerType.CUSTOMER_PRICEGROUP);
      const dsPriceListArticle = ds.filter((d) => d.customerType === CustomerType.PRICELIST_ARTICLE);
      const dsPriceListPriceGroup = ds.filter((d) => d.customerType === CustomerType.PRICELIST_PRICEGROUP);
      const dsAll = ds.filter((d) => d.customerType === CustomerType.ALL);

      const discounts: DiscountDto[] = [];
      if (dsPromotion.length > 0) {
        discounts.push(...dsPromotion);
      } else if (dsCustomerArticle.length > 0) {
        discounts.push(...dsCustomerArticle);
      } else if (dsCustomerPriceGroup.length > 0) {
        discounts.push(...dsCustomerPriceGroup);
      } else if (dsPriceListArticle.length > 0) {
        discounts.push(...dsPriceListArticle);
      } else if (dsPriceListPriceGroup.length > 0) {
        discounts.push(...dsPriceListPriceGroup);
      } else if (dsAll.length > 0) {
        discounts.push(...dsAll);
      }
      const groupByQuantity = discounts.reduce((group: { [key: number]: DiscountDto[] }, dis: DiscountDto) => {
        const fromQuantity = dis.fromQuantity;

        group[fromQuantity] = group[fromQuantity] ?? [];
        group[fromQuantity].push(dis);

        return group;
      }, {});

      if (Object.keys(groupByQuantity).length > 0) {
        const qtds = groupByQuantity[qt] ?? [];
        if (qtds.length > 1) {
          finalDiscount = getMaxDiscount(ta, qtds, segment);
        } else if (qtds.length === 1) {
          finalDiscount = qtds[0];
        } else {
          const discount = ds.sort((d1, d2) => d1.fromQuantity - d2.fromQuantity).pop();
          if (discount) {
            finalDiscount = discount;
          }
        }
      }
    }
    return finalDiscount;
  };

  const getMaxDiscount = function (ta: TradeAgreementDto, ds: DiscountDto[], segment: string | null) {
    const prices: { index: number; price: number }[] = [];
    ds.forEach((discount, index) => {
      const pr = getMaxDiscountNetPrice(ta, segment, discount);
      prices.push({ index: index, price: pr });
    });
    prices.sort((a, b) => a.price - b.price);
    const minPrice = prices[0];
    return ds[minPrice.index];
  };

  const getMaxDiscountNetPrice = function (ta: TradeAgreementDto, segment: string | null, ds?: DiscountDto) {
    let price = getPrice(ta, segment);

    // **** //
    // PAY ATTENTION THIS IS THE BEST SHIT OF THE CENTURY FOR PRODUCTION ARTICLE
    price = price ? price : 100;
    // **** //

    const maxItemDiscounts = config.company.maxItemDiscounts;
    if (ds && maxItemDiscounts > 0) {
      for (let i = 1; i <= maxItemDiscounts; i++) {
        type ObjectKey = keyof typeof ds;

        const key = `discount${i}` as ObjectKey;
        if (ds[key] && typeof ds[key] === 'number') {
          const dValue = ds[key] as number;
          price = price * (1 - dValue / 100);
        }
      }
    }
    return price;
  };

  const getPrice = function (ta: TradeAgreementDto, segment: string | null) {
    if (ta.customerType === CustomerType.ALL) {
      if (ta.salesPrice === 0 && ta.industrialPrice === 0) {
        return ta.price;
      }
      if (segment && segment.length > 0 && segment === SegmentType.INDUSTRIAL) {
        return ta.industrialPrice;
      }
      return ta.salesPrice;
    }
    return ta.price;
  };

  return {
    getPositionDiscounts,
    getFinalDiscounts,
    getFinalDiscount,
    getBaseTradeAgreements,
    getPrice,
    getMaxDiscount,
    getMaxDiscountNetPrice,
  };
}
