<template>
  <div v-if="offer && items && items.length > 0" :class="notEditableDiscountClasses">
    <!-- OVERWRITE POSITION DISCOUNTS -->
    <div v-if="props.editDiscounts">
      <div class="flex flew-row items-center justify-end gap-12 text-link">
        <div
          @click="openPopupDiscount"
          :class="{
            'cursor-pointer': isOverwriteDiscountNotDisabled,
            'text-neutral-300': !isOverwriteDiscountNotDisabled,
            'text-link': isOverwriteDiscountNotDisabled,
          }"
        >
          {{ t('offers.overwriteDiscount') }}
        </div>
        <div>
          <AgButton variant="ghost" @click="openPopupDiscount" :disabled="!isOverwriteDiscountNotDisabled">
            <template #icon>
              <IcRewrite :variant="!isOverwriteDiscountNotDisabled ? 'gray' : undefined" />
            </template>
          </AgButton>
        </div>
      </div>
    </div>
    <!-- END OVERWRITE DISCOUNT -->
    <!-- SHOPPING CART RESUME -->
    <div class="boffer-neutral-300 mb-[80px] max-lg:w-full">
      <!-- TOTAL AMOUNT -->
      <div class="flex flex-row justify-between items-center gap-4">
        <span class="font-bold basis-6/12">{{ t('offers.totalNetAmount') }}</span>
        <div class="basis-5/12 flex justify-end">
          <span class="font-bold text-l">{{ formatNumberToEuro(getTotalAmount()) }}</span>
        </div>
        <div v-if="props.editDiscounts" class="flex basis-1/12 justify-end"><!--TO MAINNTAIN THE ALIGNEMENT--></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcRewrite from '@/components/icons/IcRewrite.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { config } from '@/config/config';
  import type { OfferItemDto } from '@/domain/offerData/OfferItemDto';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToEuro } = useNumberFormatting();

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  interface Props {
    editDiscounts?: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:openPopupPositionsDiscount']);

  const notEditableDiscountClasses = computed(() => {
    return {
      'mt-12': !props.editDiscounts,
    };
  });

  const items = computed((): Array<OfferItemDto> => {
    if (!offer.value) {
      return [];
    }
    return offer.value.items;
  });

  const isOverwriteDiscountNotDisabled = computed(() => {
    if (!offer.value?.items.length) {
      return false;
    }
    return items.value.some(
      (element) => !element.tradeAgreement.isPromotional && element.quantity && element.quantity > 0
    );
  });

  const openPopupDiscount = () => {
    if (!isOverwriteDiscountNotDisabled.value) {
      return;
    }
    emit('update:openPopupPositionsDiscount', true);
  };

  const getFinalDiscountedPrice = function (item: OfferItemDto) {
    let price = item.tradeAgreement.price;
    for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
      if ((item as never)[`discount${i}`]) {
        price = price * (1 - (item as never)[`discount${i}`] / 100);
      }
    }
    if (item.quantity && item.quantity > 0) {
      return price * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getTotalAmount = function () {
    let total = 0;
    items.value.forEach((item) => {
      total += Math.round(getFinalDiscountedPrice(item) * 100) / 100; //approximate to 2 decimals
    });
    return total;
  };
</script>
