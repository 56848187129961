<template>
  <AgModal v-if="internalValue && props.customerNote" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold">{{ t('customers.editNote') }}</div>
    </template>
    <template #content>
      <div v-if="noteInModal">
        <div class="flex flex-col gap-16 pt-24 md:min-w-[333px]">
          <AgFormGroup :label="t('customers.subject')">
            <AgFormInput
              v-model="noteInModal.subject"
              :validation-state="subjectValidationState"
              :error-message="t('customers.subjectRequired')"
              :max-lenght="50"
            />
          </AgFormGroup>
          <AgFormGroup :label="t('customers.note')">
            <AgFormTextarea
              v-model="noteInModal.note"
              :rows="10"
              :validation-state="noteValidationState"
              :error-message="t('customers.noteRequired')"
              :max-lenght="300"
              :resize="false"
            />
          </AgFormGroup>
        </div>
      </div>
    </template>

    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" @click="closeModal"> {{ t('customers.cancel') }} </AgButton>
      <AgButton
        v-if="noteInModal"
        variant="primary"
        @click="save"
        :disabled="
          !noteValidationState ||
          !subjectValidationState ||
          (props.customerNote?.subject === noteInModal.subject && props.customerNote?.note === noteInModal.note)
        "
      >
        {{ t('customers.apply') }}
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { CustomerNotesDto } from '@/domain/CustomerNotesDto';
  import type { CustomerNotesRequest } from '@/domain/internal/CustomerNotesRequest';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useCustomerNotesStore } from '@/modules/customers/composables/useCustomerNotesStore';
  import { i18n } from '@/plugins/i18n';
  import { cloneDeep } from 'lodash';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useCustomerNotesStore();
  const { editCustomerNote } = store;

  interface Props {
    modelValue?: boolean;
    customerNote: CustomerNotesDto | undefined;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const subjectValidationState = ref<boolean>(false);
  const noteValidationState = ref<boolean>(false);
  const noteInModal = ref<CustomerNotesDto | undefined>(undefined);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const save = async function () {
    if (!noteInModal.value) {
      return;
    }
    const request: CustomerNotesRequest = {
      customerId: props.customerNote?.customerId ?? -1,
      subject: noteInModal.value?.subject ?? '',
      note: noteInModal.value?.note ?? '',
    };
    await editCustomerNote(props.customerNote?.id ?? -1, request);
    closeModal();
  };

  watch(
    internalValue,
    (value) => {
      if (value) {
        noteInModal.value = cloneDeep(props.customerNote);
      }
    },
    { immediate: true }
  );

  watch(
    noteInModal,
    (value) => {
      if (value) {
        if (value?.subject.length < 3) subjectValidationState.value = false;
        else subjectValidationState.value = true;

        if (value?.note.length < 3) noteValidationState.value = false;
        else noteValidationState.value = true;
      }
    },
    { immediate: true, deep: true }
  );
</script>
