<template>
  <div v-if="internalValue[props.article.id]">
    <!-- CONTENT HEADER -->
    <div class="flex flex-col text-base py-8">
      <!-- ARTICLE TITLE -->
      <span class="italic font-light text-infoBlue">{{ getTranslatedText(props.article.infoTitle) }}</span>
      <div class="text-ellipsis overflow-hidden font-bold flex flex-row items-center justify-between">
        <span class="text-l">{{ getTranslatedText(props.article?.title) }}</span>
        <AgButton variant="ghost" @click.stop="removeItem">
          <template #icon><IcClose /></template>
        </AgButton>
      </div>
      <!-- ARTICLE CODE AND EXT.CODE -->
      <div class="flex gap-16 items-center">
        <div class="flex gap-4 items-center">
          <div class="text-s text-neutral-550">{{ t('orders.articleCode') }}:&nbsp;</div>
          <div>{{ props.article.code }}</div>
        </div>
        <div v-if="props.article.externalCode" class="flex gap-16 items-center">
          <div class="text-s text-neutral-550">{{ t('orders.exCode') }}:&nbsp;</div>
          <div>{{ props.article.externalCode }}</div>
        </div>
      </div>
      <!-- ARTICLE TYPE -->
      <div class="flex gap-4 items-center">
        <div class="text-s text-neutral-550">{{ t('orders.articleType') }}:&nbsp;</div>
        <div>{{ getTranslatedText(props.article.articleType?.title) }}</div>
      </div>
      <!-- CHECK STOCK AVAILABILITY IF ENABLED  -->
      <div v-if="hasStockAvailability(props.article.articleType?.code)">
        <span class="text-s text-neutral-550">{{ t('orders.stockInfo') }}:&nbsp;</span>
        <span class="text-link text-s hover:cursor-pointer" @click.stop="showStocks = !showStocks">
          {{ t('orders.showStockInfo') }}
        </span>
        <OrderWizardPopupStocks v-model="showStocks" :articleCode="article.code" />
      </div>
    </div>

    <AgCollapsible :collapse="false" show-icon color-icon="link" disabled-auto-collapse>
      <template #header>
        <div class="flex justify-end text-s text-link font-bold">{{ t('orders.showDetails') }}</div>
      </template>
      <!-- ARTICLE AND PACKAGING -->
      <template #default>
        <div class="text-s" v-if="props.article.packagings">
          <div
            v-for="packaging in props.article.packagings"
            :key="packaging.id"
            class="border-b-2 border-b-neutral-200 pb-12"
          >
            <!-- PACKAGE -->
            <div class="flex flex-col text-base mt-8">
              <div class="font-light italic text-s w-full flex flex-row items-center">
                <span class="font-bold">{{ t('orders.packaging') }} ({{ t('orders.size') }}):&nbsp;</span>
                <span class="font-bold">{{ getTranslatedText(packaging.unit?.title) }} ({{ packaging.size }})</span>
              </div>
              <div
                v-if="config.company.modules.CONTINGENTS && currentContinget !== undefined && hasDimension()"
                class="text-s-20"
                :class="numberClass(currentContinget.available)"
              >
                <span>{{ t('orders.contingentAvailableQuantity') }}:&nbsp;</span>
                <span>{{ currentContinget.originalQuantity }}</span>
                <span>,&nbsp;qty :&nbsp;</span>
                <span>{{ getArticleTotalQty(packaging.id) }}</span>
                <span>,&nbsp;{{ t('orders.difference') }} :&nbsp;</span>
                <span>{{ currentContinget.available }}</span>
              </div>
            </div>
            <div class="flex flex-row gap-12">
              <!-- TITLE -->
              <div class="flex flex-row gap-8 pt-8 basis-1/3">
                <div class="flex flex-col">
                  <div class="flex flex-row gap-4">
                    <div class="flex flex-col gap-4">
                      <div class="font-bold">{{ t('orders.quantity') }}</div>
                      <AgFormInput
                        type="number"
                        variant="shoppingCart"
                        v-model="internalValue[article.id][packaging.id].quantity"
                        placeholder="--"
                        :validation-state="quantityValidationState(internalValue[article.id][packaging.id].quantity)"
                      />
                    </div>
                    <div class="flex flex-col gap-4" v-if="config.company.enableFreeQuantity">
                      <div class="font-bold">{{ t('orders.freeQta') }}</div>
                      <div v-if="config.company.enableFreeQuantity">
                        <AgFormInput
                          type="number"
                          variant="shoppingCart"
                          v-model="internalValue[article.id][packaging.id].freeQuantity"
                          placeholder="--"
                          :validation-state="
                            freeQuantityValidationState(internalValue[article.id][packaging.id].freeQuantity)
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- PARTIAL DELIVERY -->
                  <div v-if="isPartialiDelivery(props.article.articleType?.code)" class="mt-12">
                    <input
                      type="checkbox"
                      :id="`partialDelivery_${packaging.id}`"
                      :name="`partialDelivery_${packaging.id}`"
                      v-model="internalValue[article.id][packaging.id].requestedPartialDelivery"
                      class="hover:cursor-pointer"
                      :checked="internalValue[article.id][packaging.id].requestedPartialDelivery"
                    />
                    <label for="partialDelivery" class="ml-4">{{ t('orders.partialDelivery') }}</label>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-8 pt-8 w-full">
                <!-- DIMENSIONS -->
                <div class="flex flex-wrap gap-16 w-full basis-2/3" v-if="Object.keys(dimensions).length > 0">
                  <div
                    v-for="key in Object.keys(dimensions)"
                    :key="key"
                    :class="{ 'w-full': Object.keys(dimensions).length === 1 }"
                  >
                    <div class="font-bold mb-4">{{ getDimensionLable(key) }}</div>
                    <AgFormSelect
                      variant="shoppingCart"
                      :options="dimensions[key]"
                      :modelValue="getDimensionValue(key)"
                      @update:modelValue="setDimension($event, key, packaging.id)"
                    />
                  </div>
                </div>
                <!-- PRODUCTION ARTICLE -->
                <div v-if="isProductionArticle(props.article.articleType?.code)" class="flex gap-16 w-full basis-2/3">
                  <div class="w-full flex flex-col gap-4">
                    <span class="font-bold">{{ t('orders.productDescription') }}</span>
                    <AgFormTextarea
                      v-model="internalValue[article.id][packaging.id].productionDescription"
                      rows="2"
                      variant="shoppingCart"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>{{ t('orders.noPackageInfo') }}</div>
      </template>
    </AgCollapsible>
  </div>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { ArticlePackaging } from '@/domain/internal/ArticlePackaging';
  import type { ContingentEntry } from '@/domain/internal/ContingentEntry';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import OrderWizardPopupStocks from '@/modules/orders/components/order-wizard/steps/shopping-cart/article-table-row/OrderWizardPopupStocks.vue';

  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrdersStore } from '@/modules/orders/stores/useOrdersStore';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref, watch } from 'vue';

  const { locale, t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const { getDimOptionsByArticle } = useOrderFactory();
  const { getContingentustomerAndArticle } = useOrderFactory();

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  const orderStore = useOrdersStore();
  const { contingentMap } = storeToRefs(orderStore);

  interface Props {
    article: ShoppingCartArticleDto;
    modelValue: ArticlePackaging;
    customerId: number;
    agentId: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue', 'removeArticle']);

  const showStocks = ref<boolean>(false);
  const dimensions = ref<{ [key: string]: Array<AgFormSelectOption> }>({});
  const dimOptions = ref<{ [key: string]: Array<DimensionItemDto> }>({});

  const currentContinget = ref<ContingentEntry | undefined>({
    originalQuantity: 0,
    available: 0,
  });

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const numberClass = function (number: number | undefined): string {
    if (number === undefined) {
      return '';
    }
    return number < 0 ? 'text-infoRed' : '';
  };

  const hasStockAvailability = function (articleTypeCode: string = '') {
    return (
      config.company.enableStockAvailability &&
      config.company.partialDeliveryArticleTypeCode &&
      config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode)
    );
  };

  const isPartialiDelivery = function (articleTypeCode: string = '') {
    return config.company.partialDelivery && config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode);
  };

  const isProductionArticle = function (articleTypeCode: string = '') {
    return config.company.productionArticle && config.company.productionArticleTypeCode === articleTypeCode;
  };

  const removeItem = async () => {
    await refillContingent();
    emit('removeArticle', props.article);
  };

  const refillContingent = async () => {
    const article = internalValue.value[props.article.id];
    const artPck = article[props.article.packagings[0].id];

    if (artPck.dimensions) {
      const dimCode = config.company.contingentDimensionCode;
      const dimensionId = artPck.dimensions.find((d) => d.code === dimCode)?.value.id ?? -1;
      if (dimensionId === -1) {
        console.warn('WARING: no contingent dimensions code found !!');
        return;
      }
      const ctg = await getContingentustomerAndArticle(
        internalValue.value,
        props.article.id,
        props.article.packagings[0].id,
        props.customerId,
        dimensionId,
        props.article.contingentGroupId,
        props.agentId
      );
      if (!ctg) {
        return;
      }
      const oldCont = contingentMap.value.get(ctg.id);
      if (ctg && oldCont) {
        if (currentContinget.value) {
          currentContinget.value.available += (artPck.quantity ?? 0) + (artPck.freeQuantity ?? 0);
        }
      }
    }
  };

  const freeQuantityValidationState = function (freeQuantity: number | null) {
    if (!config.company.enableFreeQuantity) {
      return false;
    }
    if (freeQuantity != null && freeQuantity < 0) {
      return false;
    }
    return true;
  };

  const quantityValidationState = function (quantity: number | null) {
    if (quantity != null && quantity < 0) {
      return false;
    }
    return true;
  };

  const getDimensions = async function (articleId: number, priceGroupIds: Array<number>) {
    if (!order.value) {
      return;
    }
    const opts = await getDimOptionsByArticle(order.value, articleId, priceGroupIds);

    const dimensionDefaultValues: Array<DimensionItemDto> = [];
    if (opts) {
      dimOptions.value = opts;

      let dimIndex = 0;
      for (const [key, items] of Object.entries(opts)) {
        items.forEach((item, index) => {
          if (index === 0) {
            dimensionDefaultValues[dimIndex] = item;
          }
          if (dimensions.value[key]) {
            dimensions.value[key].push({
              key: item.value.id,
              label: item.value.title[locale.value],
            });
            if (item.value.default) {
              dimensionDefaultValues[dimIndex] = item;
            }
          } else {
            dimensions.value[key] = [
              {
                key: item.value.id,
                label: item.value.title[locale.value],
              },
            ];
            if (item.value.default) {
              dimensionDefaultValues[dimIndex] = item;
            }
          }
        });
        dimIndex++;
      }
      props.article.packagings.forEach((packaging) => {
        internalValue.value[props.article.id][packaging.id].dimensions = [...dimensionDefaultValues];
      });
    }
  };

  const getDimensionLable = (key: string) => dimOptions.value[key][0].title[locale.value] ?? '--';

  const getDimensionValue = function (key: string) {
    const dimensionItems = dimOptions.value[key];

    let defaultDimensionId = dimensionItems[0].value.id;
    dimensionItems.forEach((item) => {
      if (item.value.default) {
        defaultDimensionId = item.value.id;
      }
    });
    return defaultDimensionId;
  };

  const setDimension = function (id: number, key: string, packagingId: number) {
    const tempDimensions = internalValue.value[props.article.id][packagingId].dimensions || [];

    const indexToRemove = tempDimensions.findIndex((item) => item.id === parseInt(key));
    if (indexToRemove != -1) {
      tempDimensions.splice(indexToRemove, 1);
    }
    const dimensionItem = dimOptions.value[key].find((item) => item.value.id === id);
    if (dimensionItem) {
      tempDimensions.push(dimensionItem);
    }
  };

  const getArticleTotalQty = function (pckId: number) {
    const articlePckg = internalValue.value[props.article.id][pckId];
    return (articlePckg.quantity ?? 0) + (articlePckg.freeQuantity ?? 0);
  };

  const setContingentReference = async function (
    toRefill: number,
    qty: number,
    fqty: number,
    reduceQty: boolean = false,
    reduceFQty: boolean = false
  ) {
    const artPck = internalValue.value[props.article.id][props.article.packagings[0].id];
    if (!artPck || !artPck.dimensions) {
      return;
    }
    const dimCode = config.company.contingentDimensionCode;
    const dimId = artPck.dimensions.find((d) => d.code === dimCode)?.value.id ?? -1;
    if (dimId === -1) {
      console.warn('WARING: no contingent dimensions code found !!');
      return;
    }
    const contingent = await getContingentustomerAndArticle(
      internalValue.value,
      props.article.id,
      props.article.packagings[0].id,
      props.customerId,
      dimId,
      props.article.contingentGroupId,
      props.agentId
    );
    if (contingent) {
      const oldCont = contingentMap.value.get(contingent.id);

      if (oldCont) {
        if (oldCont.originalQuantity !== contingent.availableQuantity) {
          oldCont.available = contingent.availableQuantity - (qty + fqty);
        }
        oldCont.originalQuantity = contingent.availableQuantity;
        if (reduceQty) {
          oldCont.available -= qty;
        }
        if (reduceFQty) {
          oldCont.available -= fqty;
        }
        oldCont.available += toRefill;

        currentContinget.value = oldCont;
      } else if (currentContinget.value) {
        currentContinget.value.originalQuantity = contingent.availableQuantity;
        if (reduceFQty && reduceQty) {
          currentContinget.value.available = contingent.availableQuantity - (qty + fqty);
        } else if (reduceQty) {
          currentContinget.value.available = contingent.availableQuantity - qty;
        } else if (reduceFQty) {
          currentContinget.value.available = contingent.availableQuantity - fqty;
        } else {
          currentContinget.value.available = contingent.availableQuantity;
        }
        currentContinget.value.available += toRefill;

        contingentMap.value.set(contingent.id, currentContinget.value);
      }
    } else {
      currentContinget.value = undefined;
    }
  };

  const hasDimension = function () {
    return props.article.articleDimensionValueConfigIds && props.article.articleDimensionValueConfigIds.length > 0;
  };

  watch(
    () => [
      internalValue.value[props.article.id][props.article.packagings[0].id].quantity,
      internalValue.value[props.article.id][props.article.packagings[0].id].freeQuantity,
    ],
    async (newValue, oldValue) => {
      let toRefill: number = 0;
      let reduceQty: boolean = false;
      let reduceFQty: boolean = false;
      if (oldValue) {
        if (oldValue[0] !== newValue[0]) {
          toRefill += oldValue[0] ?? 0;
          reduceQty = true;
        }
        if (oldValue[1] !== newValue[1]) {
          toRefill += oldValue[1] ?? 0;
          reduceFQty = true;
        }
      }
      await setContingentReference(toRefill, newValue[0] ?? 0, newValue[1] ?? 0, reduceQty, reduceFQty);
    }
  );

  watch(
    () => internalValue.value[props.article.id][props.article.packagings[0].id].dimensions,
    () => {
      setContingentReference(0, 0, 0, false, false);
    },
    { deep: true }
  );

  onMounted(async () => {
    const hasDimesnions =
      props.article.articleDimensionValueConfigIds && props.article.articleDimensionValueConfigIds.length > 0;

    if (hasDimesnions && config.company.enabledProductionDimensions) {
      await getDimensions(props.article.id, props.article.priceGroupIds);
    }
  });
</script>
