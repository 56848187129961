<template>
  <AgBox>
    <router-link to="/open-items" class="router-link">
      <span>{{ t('invoicesDashboard') }}:</span>
      <span v-if="props.debtInfo.invoiceCount > 0">
        {{ props.debtInfo.invoiceCount }}
      </span>
      <span v-else>-</span>
    </router-link>
    <router-link to="/open-items/by-customer" class="router-link">
      <span>{{ t('customerDashboard') }}:</span>
      <span v-if="props.debtInfo.customerCount > 0">
        {{ props.debtInfo.customerCount }}
      </span>
      <span v-else>-</span>
    </router-link>
    <div class="flex items-center justify-between">
      <span>{{ t('openAmountDashboard') }}:</span>
      <span>{{ formatNumberToEuro(props.debtInfo.openAmountSum) }}</span>
    </div>
  </AgBox>
</template>

<script setup lang="ts">
  import type { OutstandingDebtInfoDto } from '@/domain/OutstandingDebtInfoDto';

  import AgBox from '@/components/library/box/AgBox.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    debtInfo: OutstandingDebtInfoDto;
  }
  const props = defineProps<Props>();

  const { formatNumberToEuro } = useNumberFormatting();
</script>

<style lang="scss" scoped>
  .router-link {
    @apply flex items-center justify-between pb-8 text-white;
  }

  .router-link:hover {
    @apply underline;
  }
</style>
