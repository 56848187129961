<template>
  <div class="table grid-cols-[minmax(150px,_1fr)_130px_130px_200px_180px]">
    <div>{{ props.order.customer.name }}</div>

    <div class="flex flex-col">
      <span>{{ props.order.agentName }}</span>
      <span v-if="props.order.agentId !== props.order.userId">{{ props.order.userName }}</span>
    </div>
    <span v-if="props.customerStatus">{{ t('statusInactive') }}</span>
    <span v-else>{{ t('statusActive') }}</span>
    <span>{{ formatDateTime(props.order.createdAt) }}</span>
    <span class="justify-self-end">{{ props.order.orderNumber }}</span>
  </div>
</template>

<script setup lang="ts">
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  const { formatDateTime } = useDateFormatting();

  interface Props {
    order: OrderDto;
    customerStatus: boolean;
  }
  const props = defineProps<Props>();
</script>

<style scoped lang="scss">
  .table {
    @apply grid min-w-fit items-center border-t border-neutral-500 py-8;
  }
  .table:hover {
    @apply bg-neutral-200;
  }
</style>
