<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <DownloadFilter
        v-model:select-lang="selectLang"
        v-model:select-cat="selectCat"
        v-model:input-search="inputSearch"
      />
    </TheSubheader>

    <!-- PAGINATED CONTENT -->
    <div class="min-w-fit mt-12 mb-32 md:mx-[80px] max-md:mx-16">
      <div v-if="downloads.content && downloads.content.length > 0" class="my-16 flex items-center justify-between">
        <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="downloads.totalElements" />
      </div>

      <!-- PAGINATOR -->
      <AgPaginator
        v-if="downloads && downloads.content && downloads.content.length > 0"
        v-model="currentPage"
        :total-pages="downloads.totalPages"
        class="my-24"
      />
      <!-- DOWNLOAD ITEMS -->
      <div class="overflow-x-auto">
        <div v-if="downloads.content.length === 0">
          <AgAlert variant="info"> {{ t('downloads.noDownload') }}</AgAlert>
        </div>
        <div v-else>
          <DownloadTable :downloads="downloads.content" v-model="sorting" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import DownloadFilter from '@/modules/downloads/components/DownloadFilter.vue';
  import DownloadTable from '@/modules/downloads/components/DownloadTable.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useDownloadStore } from '@/modules/downloads/stores/useDownloadStore';
  import { i18n } from '@/plugins/i18n';
  import { Filter } from '@/util/Filter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { ref, watch } from 'vue';

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('downloads.downloads');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const { t, locale } = i18n.global;

  const language = ref(locale.value);
  const currentPage = ref(1);
  const pageSize = ref(10);

  const store = useDownloadStore();
  const { downloads } = storeToRefs(store);

  const { loadData } = store;

  const sorting = ref<Sorting>(new Sorting('fileName', Order.ASC));
  const selectLang = ref(locale.value);
  const selectCat = ref<number>(0);
  const inputSearch = ref<string>('');

  watch(
    [language, currentPage, selectLang, selectCat, inputSearch, sorting],
    async () => {
      await loadData(
        new PageRequest(pageSize.value, currentPage.value),
        selectLang.value,
        new Filter(inputSearch.value, '', selectCat.value),
        sorting.value
      );
    },
    { immediate: true }
  );
</script>
