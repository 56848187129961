<template>
  <div class="table grid-cols-[minmax(150px,_1fr)_130px_130px_200px_30px]">
    <div>{{ props.order.customer.name }}</div>
    <div class="flex flex-col">
      <span>{{ props.order.agentName }}</span>
      <span v-if="props.order.agentId !== props.order.userId">{{ props.order.userName }}</span>
    </div>
    <div v-if="props.customerStatus">{{ t('statusInactive') }}</div>
    <div v-else>{{ t('statusActive') }}</div>
    <div>{{ formatDateTime(props.order.createdAt) }}</div>
    <div class="flex justify-end">
      <AgButton
        v-if="!props.order.orderNumber && props.order.status === OrderStatus.DRAFT"
        :title="t('orders.delete')"
        variant="ghost"
        @click.stop.prevent="emit('delete')"
      >
        <template #icon><IcDelete class="h-24 w-24" /></template>
      </AgButton>
      <div
        v-else-if="props.order.status === OrderStatus.COMPLETED && !props.order.synced"
        class="flex justify-end sync-link py-12 text-base font-light"
        :class="{ active: isSyncing, 'text-default': isSyncing, 'text-neutral-550': isSyncing }"
      >
        <IcUpdate class="h-24 w-24" />
      </div>
      <AgButton variant="ghost" v-else :title="t('orders.completed')">
        <template #icon><IcCheck class="h-24 w-24" /></template>
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useOrderData } from '@/composables/data/useOrderData';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import { i18n } from '@/plugins/i18n';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  interface Props {
    order: OrderDto;
    customerStatus: boolean;
  }
  const props = defineProps<Props>();

  const isSyncing = ref(false);

  const { isLoading } = useOrderData();

  const { formatDateTime } = useDateFormatting();

  const emit = defineEmits(['delete', 'reload-on-sync']);

  watch(
    () => isLoading.value,
    (value, oldValue) => {
      isSyncing.value = value;
      if (oldValue) {
        emit('reload-on-sync');
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .table {
    @apply grid min-w-fit items-center border-t border-neutral-500 py-8;
  }
  .table:hover {
    @apply bg-neutral-200;
  }

  .sync-link.active {
    svg {
      animation: spin-reverse 1s linear infinite;
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
</style>
