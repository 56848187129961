<template>
  <div>
    <div v-if="props.draftOffers.length === 0">
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.createdOffers') }}</span>
      </div>
      <div class="pt-12">{{ t('customers.noDataFound') }}!</div>
    </div>
    <div v-else>
      <div>
        <span class="signal">&nbsp;</span>
        <span class="font-bold">{{ t('customers.createdOffers') }}</span>
      </div>
      <div class="max-h-512 py-12">
        <div class="tableHeader">
          <div class="headerColumn">{{ t('customers.creationDate') }}</div>
          <div class="headerColumn">{{ t('customers.customerNumber') }}</div>
          <div class="headerColumn">{{ t('customers.deliveryMethod') }}</div>
          <div class="headerColumn">{{ t('customers.deliveryTerm') }}</div>
          <div class="headerColumn right">&nbsp;</div>
        </div>
        <div v-for="offer in $props.draftOffers" :key="offer.gid">
          <AgCollapsible show-icon class="border-t border-neutral-500">
            <template #header>
              <div class="table">
                <div class="tableColumn">
                  {{ formatDate(offer.createdAt) }}
                </div>
                <div class="tableColumn">
                  {{ offer.customer.code }}
                </div>
                <div class="tableColumn">
                  {{ getTranslatedText(offer.deliveryMethod?.title) }}
                </div>
                <div class="tableColumn">
                  {{ getTranslatedText(offer.deliveryTerm?.title) }}
                </div>
                <div class="tableColumn right">
                  <AgButton variant="ghost" @click.stop="onCopy(offer)" :disabled="props.isCustomerBlocked">
                    <template #icon><IcCopy /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onEdit(offer)" :disabled="props.isCustomerBlocked">
                    <template #icon><IcEdit /></template>
                  </AgButton>
                  <AgButton variant="ghost" @click.stop="onDelete(offer)">
                    <template #icon><IcDelete /></template>
                  </AgButton>
                </div>
              </div>
            </template>
            <template #default>
              <ArticlesTable :items="offer.items" />
            </template>
          </AgCollapsible>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OfferDto } from '@/domain/offerData/OfferDto';

  import IcCopy from '@/components/icons/IcCopy.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import ArticlesTable from '@/modules/customers/components/ArticlesTable.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOffers } from '@/modules/offers/composables/useOffers';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { cloneDeep } from 'lodash';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  const { copyOffer } = useOfferFactory();
  const { deleteOffer, saveOffer } = useOffers();
  const { formatDate } = useDateFormatting();
  const { getTranslatedText } = useTranslatedText();

  interface Props {
    draftOffers: Array<OfferDto>;
    isCustomerBlocked: boolean;
  }

  const props = defineProps<Props>();

  const offers = ref<Array<OfferDto>>([]);

  const onEdit = async function (offer: OfferDto) {
    await router.push({
      name: 'offer-wizard',
      params: { gid: offer.gid, step: 'customer' },
    });
  };

  const onCopy = async function (offer: OfferDto) {
    const newOffer = await copyOffer(cloneDeep(offer));

    await saveOffer(newOffer).then(
      async () =>
        await router.push({
          name: 'offer-wizard',
          params: { gid: newOffer.gid, step: 'review' },
        })
    );
  };

  const onDelete = async function (offer: OfferDto) {
    await deleteOffer(offer).then(() => offers.value.splice(offers.value.indexOf(offer), 1));
  };

  watch(
    () => props.draftOffers,
    () => (offers.value = [...props.draftOffers]),
    {
      immediate: true,
    }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
  }

  .table {
    @apply grid auto-cols-fr grid-flow-col gap-12 py-8;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .center {
    @apply justify-center;
  }

  .tooltip {
    @apply invisible absolute -mt-32 rounded bg-neutral-500 p-4;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-20;
  }

  .signal {
    padding: 0.2em;
    margin: 0 0.7em 0 0;
    background-color: #1f4092;
  }
</style>
