import type { ArticleInfoDto } from '@/domain/ArticleInfoDto';
import type { CustomerInfoDto } from '@/domain/CustomerInfoDto';
import type { ProcessedDataDto } from '@/domain/ProcessedDataDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { Filter } from '@/util/Filter';
import type { Page } from '@/util/Paging';
import type { Sorting } from '@/util/Sorting';

import { useApi } from '@/composables/api/useApi';
import { PageImpl, PageRequest } from '@/util/Paging';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useProcessedDataStore = defineStore('processed-data-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const processedData = ref<Page<ProcessedDataDto>>(new PageImpl([], new PageRequest(10, 1), 0));
  const customers = ref<Array<CustomerInfoDto>>([]);
  const articles = ref<Array<ArticleInfoDto>>([]);

  // processeddata?app_version=2023.1.4512813617&pagination.page=0&pagination.records=20&sorting.col=orderDate&filters.search=&filters.state=0
  async function loadData(pageRequest: PageRequest, filter: Filter, sorting: Sorting) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'filters.search': filter.search,
        'filters.state': `${filter.state}`,
        'filters.customerId': `${filter.customerId}`,
        'filters.articleCode': `${filter.articleCode}`,
        'filters.month': `${filter.month}`,
        'filters.year': `${filter.year}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        ...(filter.agentId ? { 'filters.agentId': `${filter.agentId}` } : {}),
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/processeddata?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.processedData) {
        const dataProcessed = container.data.processedData;
        processedData.value = api.getResultPage(container, dataProcessed);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getCustomerList(filter: Filter) {
    try {
      const requestUri = `/processeddata/customerList`;
      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.customersInfo) {
        customers.value = container.data.customersInfo;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function getArticleList(filter: Filter) {
    try {
      const requestUri = `/processeddata/articleList`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.articlesInfo) {
        articles.value = container.data.articlesInfo;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    processedData,
    customers,
    articles,
    loadData,
    getCustomerList,
    getArticleList,
  };
});
