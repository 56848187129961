import type { OutstandingDebtAgentDto } from '@/domain/OutstandingDebtAgentDto';
import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { Filter } from '@/util/Filter';
import type { Page } from '@/util/Paging';
import type { Sorting } from '@/util/Sorting';

import { useApi } from '@/composables/api/useApi';
import { PageImpl, PageRequest } from '@/util/Paging';
import { useError } from '@/util/useError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useByAgentStore = defineStore('open-item-by-agent-store', () => {
  const { handleCatchLocalError, handleCatchServerError } = useError();
  const api = useApi();

  const openItems = ref<Array<OutstandingDebtDto>>([]);

  // outstandingdebts/agents/9?app_version=2023.1.4512813617&sorting.col=customerName
  async function getAgentInvoices(agentId: number, sorting: Sorting) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/outstandingdebts/agents/${agentId}?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.outstandingDebts) {
        const res = container.data;
        openItems.value = res.outstandingDebts as Array<OutstandingDebtDto>;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  const openItemAgents = ref<Page<OutstandingDebtAgentDto>>(new PageImpl([], new PageRequest(10, 1), 0));

  // outstandingdebts/agents?app_version=2023.1.4512813617&pagination.page=0&pagination.records=20&sorting.col=agentName&filters.search=
  async function loadData(pageRequest: PageRequest, filter: Filter, sorting: Sorting) {
    try {
      const params = {
        app_version: '2023.1.4512813617',
        'pagination.page': `${pageRequest.number}`,
        'pagination.records': `${pageRequest.size}`,
        'sorting.col': sorting.col,
        'sorting.order': sorting.order,
        'filters.search': filter.search,
      };
      const searchParams = new URLSearchParams(params);
      const requestUri = `/outstandingdebts/agents/?${searchParams}`;

      const container = await api.fetch<ContainerDto>(requestUri);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
      if (container.data.outstandingDebtsAgents) {
        openItemAgents.value = api.getResultPage(container, container.data.outstandingDebtsAgents);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    openItems,
    getAgentInvoices,
    openItemAgents,
    loadData,
  };
});
