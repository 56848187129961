<template>
  <div>
    <div class="my-16 flex items-center justify-between">
      <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="props.orders.length" />
      <AgButton class="sm:min-w-[359px]" @click="onNew">{{ t('orders.newOrder') }}</AgButton>
    </div>
    <div>
      <AgPaginator v-model="currentPage" :total-pages="totalPages" />
      <div class="tableHeader grid-cols-[minmax(150px,_1fr)_130px_130px_200px_30px]">
        <div>{{ t('orders.customerName') }}</div>
        <div class="flex flex-col">
          <div>{{ t('orders.agent') }}</div>
          <div>{{ t('orders.user') }}</div>
        </div>
        <div>{{ t('orders.customerStatus') }}</div>
        <div>{{ t('orders.date') }}</div>
        <div class="flex justify-end">&nbsp;</div>
      </div>
      <div v-for="order in filteredOrders" :key="order.gid">
        <OrderTableRowDraft
          :customer-status="isCustomerBlocked(order.customer.id)"
          :order="order"
          class="cursor-pointer"
          @click="openOrder(order)"
          @delete="deleteOrder(order)"
          @reload-on-sync="reloadOrders()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import OrderTableRowDraft from '@/modules/orders/components/order-table/order-table-row/OrderTableRowDraft.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { i18n } from '@/plugins/i18n';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;
  const { tables, isFullReloading, isLoading } = useMasterData();

  interface Props {
    orders: Array<OrderDto>;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['new-entry', 'open-order', 'delete-order', 'reload-on-sync']);

  const customers = ref<Array<CustomerDto>>([]);
  const currentPage = ref(1);
  const pageSize = ref(10);

  const filteredOrders = computed(() => {
    const startIndex = (currentPage.value - 1) * pageSize.value;
    const endIndex = Math.min(startIndex + pageSize.value, props.orders.length);

    return props.orders.slice(startIndex, endIndex);
  });

  const totalPages = computed(() => Math.ceil(props.orders.length / pageSize.value));

  const onNew = function () {
    emit('new-entry');
  };

  const openOrder = function (order: OrderDto) {
    emit('open-order', order);
  };

  const deleteOrder = function (order: OrderDto) {
    emit('delete-order', order);
  };

  const reloadOrders = function () {
    emit('reload-on-sync');
  };

  const isCustomerBlocked = function (customerId?: number | null) {
    if (!customerId) {
      return false;
    }
    const customer = customers.value.find((entry) => {
      return entry.id === customerId;
    });
    if (!customer) {
      return true;
    }
    return customer.blocked;
  };

  const loadCustomers = async function () {
    customers.value = await tables.customers.toArray();
  };

  watch(
    [isFullReloading, isLoading],
    async ([fullReload, reload]) => {
      if (!fullReload && !reload) {
        await loadCustomers();
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid min-w-fit items-center py-8 font-light text-neutral-550;
  }
</style>
