<template>
  <div v-if="isLoading">
    <IcLoading />
  </div>
  <div v-else>
    <TheSubheader class="bg-white">
      <CustomerNoteFilter
        v-model:inputSearch="inputSearch"
        v-model:dateFilter="dateFilter"
        v-model:selectTypeDateFilter="selectTypeDateFilter"
      />
    </TheSubheader>

    <div v-if="items">
      <div>
        <div class="flex items-center justify-between">
          <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="items.totalElements" />
        </div>
        <!-- PAGINATOR -->
        <AgPaginator v-model="currentPage" :total-pages="items.totalPages" class="my-24" />
        <!-- CUSTOMER NOTES ITEMS -->
        <div class="overflow-x-auto">
          <!-- BUTTON ADD NOTE -->
          <div class="flex justify-end">
            <AgButton class="h-48 w-48 rounded-full" @click.stop="openPopupAddNote">
              <template #icon><IcAdd /></template>
            </AgButton>
          </div>
          <div v-if="items.content.length === 0" class="mt-12">
            <AgAlert variant="info"> {{ t('customers.noNotes') }}</AgAlert>
          </div>
          <div v-else>
            <div class="tableHeader">
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.DATE)">
                <span>{{ t('customers.createTime') }}</span>
                <span v-if="sorting.col === ColumnName.DATE && sorting.order === Order.ASC">
                  <IcChevronDown class="h-12 w-12" />
                </span>
                <span v-else-if="sorting.col === ColumnName.DATE && sorting.order === Order.DESC">
                  <IcChevronUp class="h-12 w-12" />
                </span>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.MODIFYDATE)">
                <span>{{ t('customers.modifyDate') }}</span>
                <span v-if="sorting.col === ColumnName.MODIFYDATE && sorting.order === Order.ASC">
                  <IcChevronDown class="h-12 w-12" />
                </span>
                <span v-else-if="sorting.col === ColumnName.MODIFYDATE && sorting.order === Order.DESC">
                  <IcChevronUp class="h-12 w-12" />
                </span>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.SUBJECT)">
                <span>{{ t('customers.subject') }}</span>
                <span v-if="sorting.col === ColumnName.SUBJECT && sorting.order === Order.ASC">
                  <IcChevronDown class="h-12 w-12" />
                </span>
                <span v-else-if="sorting.col === ColumnName.SUBJECT && sorting.order === Order.DESC">
                  <IcChevronUp class="h-12 w-12" />
                </span>
              </div>
              <div class="headerColumn" @click.stop.prevent="setOrder(ColumnName.NOTE)">
                <span>{{ t('customers.note') }}</span>
                <span v-if="sorting.col === ColumnName.NOTE && sorting.order === Order.ASC">
                  <IcChevronDown class="h-12 w-12" />
                </span>
                <span v-else-if="sorting.col === ColumnName.NOTE && sorting.order === Order.DESC">
                  <IcChevronUp class="h-12 w-12" />
                </span>
              </div>
              <div class="headerColumn right">
                <span>&nbsp;</span>
              </div>
            </div>
            <div v-for="note in items.content" :key="note.id">
              <div class="table">
                <div class="tableColumn">{{ formatDate(note.createTimestamp) }}</div>
                <div class="tableColumn">
                  {{ formatDate(note.modifyTimestamp) }}
                </div>
                <div class="tableColumn break-all">
                  {{ note.subject }}
                </div>
                <div class="tableColumn whitespace-pre-wrap max-h-[133px] break-all overflow-y-scroll">
                  {{ note.note }}
                </div>
                <div class="tableColumn cursor-pointer content-end" @click="openPopupEditNote(note)">
                  <AgButton variant="ghost" class="pr-12">
                    <template #icon>
                      <IcEdit class="h-24 w-24 hover:text-primary-light" />
                    </template>
                  </AgButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerNotePopup v-model="showModal" :customerId="$props.customerId"></CustomerNotePopup>
      <CustomerNotePopupEditNote v-model="showModalEdit" :customer-note="noteToEdit"></CustomerNotePopupEditNote>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CustomerNotesDto } from '@/domain/CustomerNotesDto';

  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import CustomerNoteFilter from '@/modules/customers/components/customer-notes/CustomerNoteFilter.vue';
  import CustomerNotePopup from '@/modules/customers/components/customer-notes/CustomerNotePopup.vue';
  import CustomerNotePopupEditNote from '@/modules/customers/components/customer-notes/CustomerNotePopupEditNote.vue';

  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useCustomerNotesStore } from '@/modules/customers/composables/useCustomerNotesStore';
  import { i18n } from '@/plugins/i18n';
  import { Filter } from '@/util/Filter';
  import { Order } from '@/util/Order';
  import { PageRequest, type Page } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { formatDate } = useDateFormatting();

  const store = useCustomerNotesStore();
  const { loadData } = store;

  const { customerNotes } = storeToRefs(store);

  interface Props {
    customerId: number;
  }
  const props = defineProps<Props>();

  enum ColumnName {
    DATE = 'createtimestamp',
    NOTE = 'note',
    SUBJECT = 'SUBJECT',
    MODIFYDATE = 'modifytimestamp',
  }
  const isLoading = ref<boolean>(true);
  const selectTypeDateFilter = ref<number>(0);
  const sorting = ref<Sorting>(new Sorting('createtimestamp', Order.DESC));
  const inputSearch = ref<string>('');
  const dateFilter = ref<string>(new Date().toISOString().split('T')[0]);
  const currentPage = ref(1);
  const pageSize = ref(5);
  const showModal = ref<boolean>(false);
  const showModalEdit = ref<boolean>(false);
  const noteToEdit = ref<CustomerNotesDto>();

  const openPopupAddNote = () => {
    showModal.value = true;
  };

  const openPopupEditNote = (note: CustomerNotesDto) => {
    showModalEdit.value = true;
    noteToEdit.value = note;
  };

  const items = computed((): Page<CustomerNotesDto> | undefined => {
    if (!customerNotes.value) {
      return undefined;
    }
    return customerNotes.value;
  });

  const setOrder = (column: string) => {
    if (sorting.value.col === column) {
      if (sorting.value.order === Order.ASC) {
        sorting.value.order = Order.DESC;
      } else {
        sorting.value.order = Order.ASC;
      }
    } else {
      sorting.value.col = column;
      sorting.value.order = Order.ASC;
    }
  };

  watch(
    [showModal, showModalEdit, currentPage, inputSearch, sorting.value, selectTypeDateFilter, dateFilter],
    async () => {
      await loadData(
        props.customerId,
        new PageRequest(pageSize.value, currentPage.value),
        sorting.value,
        new Filter(inputSearch.value, dateFilter.value, undefined, undefined, undefined, selectTypeDateFilter.value)
      ).then(() => (isLoading.value = false));
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[minmax(80px,133px)_minmax(80px,133px)_minmax(133px,_1fr)_minmax(133px,_1fr)_33px] gap-8 py-4 font-light text-neutral-550 hover:cursor-pointer;
  }

  .headerColumn {
    @apply flex items-center;
  }

  .table {
    @apply grid grid-cols-[minmax(80px,133px)_minmax(80px,133px)_minmax(133px,_1fr)_minmax(133px,_1fr)_33px] gap-8 py-4 border-t border-neutral-500;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .table:hover {
    @apply bg-neutral-200;
  }
</style>
