<template>
  <div v-if="order && items && items.length > 0" :class="notEditableDiscountClasses">
    <!-- OVERWRITE POSITION DISCOUNTS -->
    <div v-if="props.editDiscounts">
      <div class="flex flew-row items-center justify-end gap-12 text-link">
        <div
          @click="openPopupDiscount"
          :class="{
            'cursor-pointer': isOverwriteDiscountNotDisabled,
            'text-neutral-300': !isOverwriteDiscountNotDisabled,
            'text-link': isOverwriteDiscountNotDisabled,
          }"
        >
          {{ t('orders.overwriteDiscount') }}
        </div>
        <div>
          <AgButton variant="ghost" @click="openPopupDiscount" :disabled="!isOverwriteDiscountNotDisabled">
            <template #icon>
              <IcRewrite :variant="!isOverwriteDiscountNotDisabled ? 'gray' : undefined" />
            </template>
          </AgButton>
        </div>
      </div>
    </div>
    <!-- END OVERWRITE DISCOUNT -->
    <!-- SHOPPING CART RESUME -->
    <div class="border-neutral-300 mb-[80px] max-lg:w-full">
      <!-- TOTAL AMOUNT -->
      <div class="flex flex-row justify-between items-center gap-4">
        <span class="font-bold basis-6/12">{{ t('orders.totalNetAmount') }}</span>
        <div class="basis-5/12 flex justify-end">
          <span class="font-bold text-l">{{ formatNumberToEuro(getTotalAmount()) }}</span>
        </div>
        <div v-if="props.editDiscounts" class="flex basis-1/12 justify-end"><!--TO MAINNTAIN THE ALIGNEMENT--></div>
      </div>
      <!-- CART DISCOUNT -->
      <div
        v-if="config.company.calculateFinalPrice"
        class="flex flex-row justify-between items-center gap-4"
        :class="notEditableDiscountClasses"
      >
        <span class="font-bold basis-2/12">{{ t('orders.cartDiscount') }}</span>
        <div class="basis-3/12 flex justify-end">
          <span class="text-l">-&nbsp;{{ formatNumberToPercentage(order.discount) }}</span>
        </div>
        <div class="basis-3/12 flex justify-end">
          <span class="text-l">-&nbsp;{{ formatNumberToEuro(getShoppingCartDiscountAmount()) }}</span>
        </div>
        <div class="basis-3/12 flex justify-end">
          <span class="text-l">{{ formatNumberToEuro(getTotalAmountWithCartDiscount()) }}</span>
        </div>
        <!-- CART DISCOUNT -->
        <div class="flex basis-1/12 justify-end" v-if="props.editDiscounts">
          <AgButton variant="ghost">
            <template #icon>
              <IcEdit
                v-if="!config.company.disabledHeadDiscount"
                class="h-24 w-24 hover:cursor-pointer hover:text-link"
                @click="emit('update:openPopupShoppingCartDiscount', true)"
              />
            </template>
          </AgButton>
        </div>
      </div>
      <!-- TAXES -->
      <div
        v-if="config.company.enableTaxCalculator && articleTaxes.size > 0"
        class="flex flex-row justify-between items-center gap-4"
        :class="notEditableDiscountClasses"
      >
        <span class="font-bold basis-2/12">{{ t('orders.withTax') }}</span>
        <div class="basis-3/12 flex justify-end">
          <span>&nbsp;</span>
        </div>
        <div class="basis-3/12 flex justify-end">
          <span class="text-l">+&nbsp;{{ formatNumberToEuro(getTaxAmount()) }}</span>
        </div>
        <span class="flex basis-3/12 justify-end font-bold text-l">
          {{ formatNumberToEuro(getTotalAmountWithTaxes()) }}
        </span>
        <div v-if="props.editDiscounts" class="flex basis-1/12 justify-end"><!--TO MAINNTAIN THE ALIGNEMENT--></div>
      </div>
      <!-- TAX ERROR -->
      <div
        v-if="config.company.enableTaxCalculator && showVatError"
        class="flex flex-row justify-between items-center my-12"
      >
        <AgAlert variant="danger" class="w-full font-bold">
          {{ t(`orders.vatError_${vatErrorCode}`) }}
          <template #icon><IcAlert /></template>
        </AgAlert>
      </div>
      <!-- END TAXES -->
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcAlert from '@/components/icons/IcAlert.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import IcRewrite from '@/components/icons/IcRewrite.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { config } from '@/config/config';
  import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();
  const { getArticleCustomerTax } = useOrderFactory();

  const orderStore = useOrderWizardStore();
  const { order } = storeToRefs(orderStore);

  interface Props {
    editDiscounts?: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits([
    'update:openPopupShoppingCartDiscount',
    'update:openPopupPositionsDiscount',
    'update:articleTaxes',
  ]);

  enum VatErrorCodes {
    CUSTOMER = 'VAT_NOT_FOUND_FOR_CUSTOMER',
    SOME_ARTICLE = 'VAT_NOT_FOUND_FOR_SOME_ARTICLE',
  }

  const vatErrorCode = ref<VatErrorCodes>();
  const articleTaxes = ref<Map<number, number>>(new Map<number, number>());

  const notEditableDiscountClasses = computed(() => {
    return {
      'mt-12': !props.editDiscounts,
    };
  });

  const items = computed((): Array<OrderItemDto> => {
    if (!order.value) {
      return [];
    }
    return order.value.items;
  });

  const showVatError = computed(() => {
    const hasCustomerNoTax: boolean = order.value?.customer.customerTaxCode ? false : true;
    const hasNoArticleTax = articleTaxes.value.size === 0;
    const hasSomeArticleNoTax = items.value.some((item) => !articleTaxes.value.has(item.article.id));

    if (hasCustomerNoTax) {
      vatErrorCode.value = VatErrorCodes.CUSTOMER;
    } else if (hasNoArticleTax || hasSomeArticleNoTax) {
      vatErrorCode.value = VatErrorCodes.SOME_ARTICLE;
    }

    return hasCustomerNoTax || hasNoArticleTax || hasSomeArticleNoTax;
  });

  const isOverwriteDiscountNotDisabled = computed(() => {
    if (!order.value?.items.length) {
      return false;
    }
    return items.value.some(
      (element) => !element.tradeAgreement.isPromotional && element.quantity && element.quantity > 0
    );
  });

  const openPopupDiscount = () => {
    if (!isOverwriteDiscountNotDisabled.value) {
      return;
    }
    emit('update:openPopupPositionsDiscount', true);
  };

  const getFinalDiscountedPrice = function (item: OrderItemDto) {
    let price = item.tradeAgreement.price;
    for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
      if ((item as never)[`discount${i}`]) {
        price = price * (1 - (item as never)[`discount${i}`] / 100);
      }
    }
    if (item.quantity && item.quantity > 0) {
      return price * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getTotalAmount = function () {
    let total = 0;
    items.value.forEach((item) => {
      total += Math.round(getFinalDiscountedPrice(item) * 100) / 100; //approximate to 2 decimals
    });
    return total;
  };

  const getTotalAmountWithTaxes = function (): number {
    if (!order.value) {
      return 0;
    }
    if (!order.value.customer.customerTaxCode) {
      return getTotalAmountWithCartDiscount();
    }
    const total = getTaxAmount() + getTotalAmountWithCartDiscount();
    return total;
  };

  const getTotalAmountWithCartDiscount = function (finalPriceNotDiscounted?: number) {
    const priceToBeDiscounted = finalPriceNotDiscounted !== undefined ? finalPriceNotDiscounted : getTotalAmount();
    if (!order.value) {
      return getTotalAmount();
    }
    if (order.value.discount === 0) {
      return priceToBeDiscounted;
    }
    const result = priceToBeDiscounted - (priceToBeDiscounted * order.value?.discount) / 100;
    return result;
  };

  const getTaxAmount = function () {
    let totalTax = 0;
    items.value.forEach((item) => {
      if (item.tax) {
        const finalPrice = Math.round(getFinalDiscountedPrice(item) * 100) / 100; // ARTICLE FINAL PRICE ROUNDED TO 2 DECIMALS
        const finalAmountWithCartDiscount = getTotalAmountWithCartDiscount(finalPrice); // FINAL PRICE - CART DISCOUNT
        const taxAmount = Math.round(finalAmountWithCartDiscount * (item.tax / 100) * 100) / 100; // VAT AMOUNT ROUNDED TO 2 DECIMALS FOR EACH ARTICLE
        totalTax += taxAmount; // ADD TO TOTAL TAX
      }
    });
    return totalTax;
  };

  const getShoppingCartDiscountAmount = function () {
    const priceToBeDiscounted = getTotalAmount();
    if (!order.value) {
      return 0;
    }
    if (order.value.discount === 0) {
      return 0;
    }
    const result = (priceToBeDiscounted * order.value?.discount) / 100;
    return result;
  };

  const loadArticleTaxes = function () {
    articleTaxes.value.clear();
    items.value.forEach(async (item) => {
      if (!order.value) {
        return;
      }
      const taxPercentage = await getArticleCustomerTax(order.value.customer.customerTaxCode, item.article.id);
      if (taxPercentage !== undefined) {
        articleTaxes.value.set(item.article.id, taxPercentage);
      }
    });
  };

  watch(
    items,
    () => {
      loadArticleTaxes();
      emit('update:articleTaxes', articleTaxes.value);
    },
    { immediate: true, deep: true }
  );
</script>
