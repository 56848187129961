<template>
  <div class="my-12 space-y-12">
    <AgBox class="overflow-y-auto">
      <SentOffersTable :is-customer-blocked="isCustomerBlocked" :sent-offers="sentOffers" />
    </AgBox>
    <AgBox class="overflow-y-auto">
      <DraftOffersTable :is-customer-blocked="isCustomerBlocked" :draft-offers="draftOffers" />
    </AgBox>
  </div>
</template>

<script setup lang="ts">
  import type { CustomerDto } from '@/domain/masterData/CustomerDto';
  import type { OfferDto } from '@/domain/offerData/OfferDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import DraftOffersTable from '@/modules/customers/components/offers/DraftOffersTable.vue';
  import SentOffersTable from '@/modules/customers/components/offers/SentOffersTable.vue';

  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import { useOffers } from '@/modules/offers/composables/useOffers';
  import { ref, watch } from 'vue';

  const { listOffersByCustomerVatCode } = useOffers();

  interface Props {
    customer: CustomerDto | null;
  }
  const props = withDefaults(defineProps<Props>(), {
    customer: null,
  });

  const isCustomerBlocked = ref<boolean>(false);
  const sentOffers = ref<Array<OfferDto>>([]);
  const draftOffers = ref<Array<OfferDto>>([]);

  const loadOffers = async function () {
    if (props.customer && props.customer.vatCode) {
      isCustomerBlocked.value = props.customer.blocked;

      const offers: OfferDto[] = await listOffersByCustomerVatCode(props.customer.vatCode);

      sentOffers.value = offers.filter((o) => o.status === OfferStatus.COMPLETED);
      draftOffers.value = offers.filter((o) => o.status === OfferStatus.DRAFT);
    } else {
      isCustomerBlocked.value = false;
      sentOffers.value = [];
      draftOffers.value = [];
    }
  };

  watch(() => props.customer, loadOffers, { immediate: true });
</script>
