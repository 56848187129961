export class Filter {
  search: string;
  date: string;
  category?: number;
  due?: number;
  state?: number;
  typeDateFilter?: number;
  agentId?: number | null;
  customerId?: number;
  articleCode?: string;
  month?: number;
  year?: number;

  constructor(
    search = '',
    date = '',
    category?: number,
    due?: number,
    state?: number,
    typeDateFilter?: number,
    agentId?: number | null,
    customerId?: number,
    articleCode?: string,
    month?: number,
    year?: number
  ) {
    this.search = search;
    this.date = date;

    if (undefined !== category) {
      this.category = category;
    }
    if (undefined !== due) {
      this.due = due;
    }
    if (undefined !== state) {
      this.state = state;
    }
    if (undefined !== typeDateFilter) {
      this.typeDateFilter = typeDateFilter;
    }
    if (undefined !== agentId) {
      this.agentId = agentId;
    }
    if (undefined !== customerId) {
      this.customerId = customerId;
    }
    if (undefined !== articleCode) {
      this.articleCode = articleCode;
    }
    if (undefined !== month) {
      this.month = month;
    }
    if (undefined !== year) {
      this.year = year;
    }
  }
}
