import { useApi } from '@/composables/api/useApi';
import { useLocalDatabase } from '@/composables/data/local-database/useLocalDatabase';
import { useIntegrityCheck } from '@/composables/useIntegrityCheck';
import type { ContainerDto } from '@/domain/data/ContainerDto';
import type { DataInfo } from '@/domain/data/DataInfo';
import { useDataTimestampStore } from '@/store/useDataTimestampStore';
import { useError } from '@/util/useError';
import type { Table } from 'dexie';
import { ref } from 'vue';

const TIMESTAMP_MASTER_DATA = 'data';
const MODIFY_TIMESTAMP_MASTER_DATA = 'dataModify';

const SYNC_TABLES: Array<string> = [
  'articles',
  'articleDimensionValueConfigs',
  'businessCategories',
  'countries',
  'customers',
  'deliveryMethods',
  'deliveryTerms',
  'discounts',
  'paymentMethods',
  'paymentTerms',
  'priceLists',
  'discountLists',
  'priceGroups',
  'tradeAgreements',
  'articleExternalCodes',
  'articleGroups',
  'articleTypes',
  'contingentGroupArticles',
  'contingents',
  'taxes',
];

const isLoading = ref(false);
const isFullReloading = ref(false);

let intervalData: number | null = null;

export function useMasterData() {
  const db = useLocalDatabase();
  const api = useApi();

  const { handleCatchLocalError, handleCatchServerError } = useError();
  const { refreshMasterDataTimestamp } = useDataTimestampStore();
  const { checkMasterData } = useIntegrityCheck();

  function currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  function getMasterDataTable(tableName: string): Table | undefined {
    if (tableName in db.masterData) {
      return (db.masterData as never)[tableName] as Table;
    }
  }

  const updateMasterDataObjectsForTable = async function <T>(data: DataInfo<T>, tableName: string) {
    const table = getMasterDataTable(tableName);
    if (!table) {
      return;
    }
    await Promise.all([table.bulkPut(data.upsert), table.bulkDelete(data.delete)]);
  };

  const updateMasterDataTimestampTo = async function (timestamp: number) {
    db.masterData.timestamps.put({
      id: TIMESTAMP_MASTER_DATA,
      value: timestamp,
    });
  };

  const updateMasterDataModifyTimestampTo = async function (timestamp: number) {
    db.masterData.timestamps.put({
      id: MODIFY_TIMESTAMP_MASTER_DATA,
      value: timestamp,
    });
  };

  const getMasterDataModifyTimestamp = async function () {
    const timestamp = await db.masterData.timestamps.get(MODIFY_TIMESTAMP_MASTER_DATA);
    if (!timestamp) {
      return 0;
    }
    return timestamp.value || 0;
  };

  async function updateMasterData(responseContainer: ContainerDto) {
    SYNC_TABLES.map((table) => {
      updateMasterDataObjectsForTable((responseContainer.data as never)[table], table);
    });
    await updateMasterDataTimestampTo(currentTimestamp());
    await updateMasterDataModifyTimestampTo(responseContainer.modifyTimestamp || 0);

    refreshMasterDataTimestamp(Date.now());
  }

  const syncMasterData = async function () {
    if (isLoading.value) {
      return;
    }
    console.log('*** SYNC MASTER DATA ***', new Date().toISOString());

    isLoading.value = true;

    try {
      const timestamp = await getMasterDataModifyTimestamp();

      const responseContainer = await api.fetch<ContainerDto>('/masterdata?timestamp=' + timestamp);
      if (responseContainer.errors && responseContainer.errors.length > 0) {
        handleCatchServerError(responseContainer.errors);
        isLoading.value = false;
        return;
      }
      if (Object.keys(responseContainer.data).length === 0) {
        isLoading.value = false;
        return;
      }
      await updateMasterData(responseContainer);

      const integrity = await checkMasterData(SYNC_TABLES, responseContainer);

      isLoading.value = false;

      if (!integrity) {
        await reloadMasterData();
      }
    } catch (error) {
      isLoading.value = false;
      handleCatchLocalError(error);
    }
  };

  const reloadMasterData = async function (retryCount = 0) {
    if (isFullReloading.value) {
      return;
    }
    isFullReloading.value = true;

    try {
      const timestamp = 0;

      const responseContainer = await api.fetch<ContainerDto>('/masterdata?timestamp=' + timestamp);
      if (responseContainer.errors && responseContainer.errors.length > 0) {
        handleCatchServerError(responseContainer.errors);
        isLoading.value = false;
        return;
      }
      if (Object.keys(responseContainer.data).length === 0) {
        isLoading.value = false;
        return;
      }
      db.clearMasterData();

      await updateMasterData(responseContainer);

      const integrity = await checkMasterData(SYNC_TABLES, responseContainer);

      isFullReloading.value = false;

      if (integrity) {
        return;
      }
      if (retryCount > 2) {
        db.clearMasterData();
        return;
      }
      retryCount++;
      await reloadMasterData(retryCount);
    } catch (error) {
      isFullReloading.value = false;
      handleCatchLocalError(error);
    }
  };

  const setIntervalSyncMasterData = function () {
    if (intervalData === null) {
      intervalData = window.setInterval(async () => await syncMasterData(), 15 * 60 * 1000); // Every 15 minutes
    }
  };

  const clearSyncMasterData = function () {
    if (intervalData !== null) {
      clearInterval(intervalData);
      intervalData = null;
    }
  };

  return {
    setIntervalSyncMasterData,
    clearSyncMasterData,
    syncMasterData,
    reloadMasterData,
    isLoading,
    isFullReloading,
    tables: db.masterData,
  };
}
